<script>
import EmptyList from '@/components/general/EmptyList'
import InputField from '@/components/general/InputField'
import ListItem from '@/components/general/ListItem'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import Loading from '@/components/general/Loading.vue'
export default {
  name: 'Bookmarks',
  components: {
    EmptyList,
    InputField,
    ListItem,
    Breadcrumbs,
    Loading
  },
  data () {
    return {
      search: '',
      isReady: false,
      itemsLength: 0,
      closeModal: false,
      data: [],
      filteredItems: [],
      formData: {
        _id: '',
        name: '',
        socialName: '',
        cnpj: '',
        mainContact: '',
        phone: '',
        email: '',
        observations: ''
      }
    }
  },
  created () {
    this.getDependents()
  },
  methods: {
    getDependents () {
      this.$store.dispatch('attemptGetDependents')
        .then(({ data }) => {
          this.filteredItems = data.sort((a, b) => a.businessName.localeCompare(b.businessName))
          this.data = data
          this.isReady = true
          this.formData.name = data.businessName
          this.itemsLength = data.length
        })
    },
    deleteDependentById (dependetId) {
      this.$store.dispatch('attemptDeleteDependent', dependetId).then(() => {
        this.closeModal = true
        this.getDependents()
      })
    }
  },
  watch: {
    search: function (value) {
      const filter = this.filteredItems = this.data.filter(item => {
        return item.businessName.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
      this.itemsLength = filter.length
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'admin.center:menu.2:title', href: this.$route.path }]
      return path
    }
  }
}
</script>
<template>
  <section class="bookmarks--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('admin.center:bookmarks.title') }}</h2>
    </div>
    <Loading v-if="!isReady"/>
    <div v-else>
      <div class="bookmarks--filter-actions">
        <input-field class="mr-2" outlined dense :label="$t('global:search.alt')" v-model="search"
          append-icon="mdi-magnify"></input-field>
        <v-btn class="btn transform-unset" :color="getPrimaryColor" dark
          @click="$router.push({ name: 'business.admin.center.bookmarks.new' })">
          <v-icon size="18px" class="mr-2" color="#fff">mdi-plus</v-icon>{{ $t('admin.center.bookmarks:add.bookmarker.btn') }}
        </v-btn>
      </div>
      <div class="bookmarks--list">
        <list-item v-for="item in filteredItems" :key="item._id" :text=item.businessName v-model="search"
          :modalConfirmTitle="$t('modal.confirmation:bookmarks.removal.title')"
          :modalConfirmDescription="$t('modal.confirmation:bookmarks.removal.description', { name: item.businessName })"
          @edit="$router.push({ name: 'business.admin.center.bookmarks.manage', params: { id: item._id } })" pencilBtn
          @cancel="showConfirmationModal = closeModal" @deleteItem="deleteDependentById(item._id)" deleteable></list-item>

        <empty-list v-if="itemsLength === 0" :title="$t('empty.state.title:bookmark.title')"
          :textOne="$t('empty.state.text:bookmark.text')" :image="'/assets/images/empty-bush.png'"></empty-list>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.bookmarks--container {
  padding-bottom: 60px;
  .bookmarks--filter-actions {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
}
</style>
